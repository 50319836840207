import React from 'react'
import email from '../../images/email.png'
import phone from '../../images/smartphone.png'
import WebriQForm from '@webriq/gatsby-webriq-form'

class ContactPage extends React.Component {
  render() {
    return (
      <section id="contact">
        <div className="container">
          <div className="row">
            <div className="col-md-5 border-right">
              <div className="get-touch">
                <h2 className="text-white text-left mb-4">
                  Getting in touch is easy!
                </h2>
                <div className="info mb-3">
                  <h5 className="mb-3">
                    For Local, Regional, and National sales call:
                  </h5>
                  {/*<h5 className="contact-name">
                    David Braunstein, National Sales Executive
                  </h5>
                  
                  <br />
                  <br />
                  <div className="mail mb-2">
                    <img
                      src={email}
                      className="img-fluid mr-2"
                      alt="Email Icon"
                    />
                    <a
                      href="mailto:david@trimexoutdoor.com"
                      className="contact-link"
                    >
                      david@trimexoutdoor.com
                    </a>
                  </div>
                  <div className="tel">
                    <img
                      src={phone}
                      className="img-fluid mr-2"
                      alt="Phone Icon"
                    />
                    <a href="tel:4437949187" className="contact-link">
                      (443) 794-9187
                    </a>
                  </div>*/}
                </div>
                <br />
                <br />
                <div className="info mb-3">
                  <h5 className="contact-name">Tim Cuny, CEO</h5>
                  <div className="mail mb-2">
                    <img
                      src={email}
                      className="img-fluid mr-2"
                      alt="Email Icon"
                    />
                    <a
                      href="mailto:tim@trimexoutdoor.com"
                      className="contact-link"
                    >
                      tim@trimexoutdoor.com
                    </a>
                  </div>
                  <div className="tel">
                    <img
                      src={phone}
                      className="img-fluid mr-2"
                      alt="Phone Icon"
                    />
                    <a href="tel:7607165585" className="contact-link">
                      (760) 716-5585
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="form">
                <h2 className="text-white p-0 mb-4">Contact Us</h2>
                <WebriQForm
                  data-thankyou-url="/thank-you"
                  name="contact"
                  method="POST"
                  data-form-id="5cda89cb4c9d4a1250b903ae"
                >
                  <div className="form-group">
                    <label htmlFor="inputName">Your Name</label>
                    <input
                      className="form-control"
                      id="inputName"
                      type="text"
                      name="Name"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputEmail">Email</label>
                    <input
                      id="inputEmail"
                      className="form-control"
                      type="text"
                      name="Email"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputSubject">Subject (Optional)</label>
                    <input
                      id="inputSubject"
                      className="form-control"
                      type="text"
                      name="Subject"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputMessage">Message</label>
                    <textarea
                      id="inputMessage"
                      className="form-control"
                      name="Message"
                    />
                  </div>
                  <div className="form-group">
                    <div className="webriq-recaptcha" />
                  </div>
                  <button className="btn btn-primary btn-submit" type="submit">
                    SUBMIT
                  </button>
                </WebriQForm>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
export default ContactPage
