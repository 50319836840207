import React from 'react'
import Layout from '../layouts'
// import { Table } from 'reactstrap';
import Contact from '../components/contact/contact.js'
import David from '../images/David-Braunstein.jpg'

// const InMemoryOf = () => {
// 	return (
// 		<Layout head={{ title: 'In memory of' }}>
// 			<section id="title">
// 				<div className="container">
// 					<div className="row">
// 						<div className="col text-center">
// 							<h1 className="heading-title">
// 								“In memory of David Braunstein March 15th 1967 – June 14th
// 								2021.”
// 							</h1>
// 						</div>
// 					</div>
// 				</div>
// 			</section>

// 			<section id="lunchtruck" className="text-center">
// 				<div className="container">
// 					<div className="row">
// 						<div className="col">
// 							<a
// 								href="https://www.legacy.com/obituaries/baltimoresun/obituary.aspx?n=david-p-braunstein&pid=199071459&fhid=10909"
// 								target="_blank"
// 								rel="noreferrer"
// 							>
// 								<img
// 									src={David}
// 									className="img-fluid img-border"
// 									alt="David Braunstein"
// 								/>
// 							</a>

// 							<p className="section-p">
// 								On June 14, 2021, David P. Braunstein, of Bethesda, MD, passed
// 								away suddenly at the age of 54. Beloved husband of Felicia
// 								Braunstein; devoted father of Skyler Braunstein; loving son of
// 								Helen Weinstein and the late Saul Braunstein; loving brother of
// 								Neil Braunstein and his wife Illice; cherished son-in-law of
// 								Perry Palan and the late Rubetta Palan, and brothers-in-law
// 								Stephen Palan and Michael Palan, and their wives Liz and Sara;
// 								and fun-loving uncle to Shayna, Chloe, Nina, Owen, and Olive.
// 							</p>

// 							<p className="section-p">
// 								David will be remembered for his adventurous spirit, always
// 								trying new foods, new restaurants, going to festivals throughout
// 								the area, as well as day trips to Rehoboth Beach with his
// 								family. He was playful and found joy in simple things that many
// 								people overlook. David was a devoted father, husband, son,
// 								brother, nephew and cousin who made his family a priority. He
// 								was a devoted and supportive husband and was so close to his
// 								daughter Skyler that he was one of her best friends.
// 							</p>

// 							<p className="section-p">
// 								Graveside funeral services will be held on Thursday, June 17,
// 								2021, at 10 a.m. at Judean Memorial Gardens in Olney, MD.
// 								David's family will be observing Shiva following the graveside
// 								service, ending at 5 pm and Friday from 4-8 pm at the residence
// 								of his brother-in-law, Stephen Palan, 6007 Anniston Road,
// 								Bethesda, MD 20817.
// 							</p>

// 							<p className="section-p">
// 								In lieu of memorial contributions, please hold your loved ones
// 								close, as David always viewed family as an invaluable gift.
// 								Services entrusted to Sagel Bloomfield Danzansky Goldberg
// 								Funeral Care.
// 							</p>
// 						</div>
// 					</div>
// 				</div>
// 			</section>
// 			<Contact />
// 		</Layout>
// 	)
// }

const InMemoryOf = () => (
  <Layout>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default InMemoryOf
